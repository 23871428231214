import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e9919582"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-TUIKit-main" }
const _hoisted_2 = { class: "chat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TUIConversation = _resolveComponent("TUIConversation")!
  const _component_TUIChat = _resolveComponent("TUIChat")!
  const _component_TUICallKit = _resolveComponent("TUICallKit")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(_ctx.env?.isH5 ? 'conversation-h5' : 'conversation')
      }, [
        _createVNode(_component_TUIConversation, { onCurrent: _ctx.handleCurrentConversation }, null, 8, ["onCurrent"])
      ], 2), [
        [_vShow, !_ctx.env?.isH5 || _ctx.currentModel === 'conversation']
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TUIChat)
      ], 512), [
        [_vShow, !_ctx.env?.isH5 || _ctx.currentModel === 'message']
      ]),
      _createVNode(_component_TUICallKit, {
        class: _normalizeClass(!_ctx.showCallMini ? 'callkit-drag-container' : 'callkit-drag-container-mini'),
        allowedMinimized: true,
        allowedFullScreen: false,
        beforeCalling: _ctx.beforeCalling,
        afterCalling: _ctx.afterCalling,
        onMinimized: _ctx.onMinimized,
        onMessageSentByMe: _ctx.onMessageSentByMe
      }, null, 8, ["class", "beforeCalling", "afterCalling", "onMinimized", "onMessageSentByMe"])
    ])
  ], 64))
}