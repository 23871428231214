import { createApp } from 'vue';
import App from './App.vue';
import Call from './CallAutio.vue';
import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';
// import TUICallKit
import { TUICallKit } from '@tencentcloud/call-uikit-vue';
import { TUICallKitServer, TUICallType } from "@tencentcloud/call-uikit-vue";
import router from './router/index'

const SDKAppID = 1600018481; 
const secretKey = 'dc561acf23c8e8f21bf5672d0c4debaf6fde1a460c6dce07de8320413a208eae'; 
// const userID = 'admin'; // User ID

// init TUIKit
const TUIKit = TUICore.init({
  SDKAppID,
});
TUIKit.use(TUIComponents);
TUIKit.use(TUICallKit);

const params=new URLSearchParams(window.location.search);
const userID:string=params.get('name')||'';
// login TUIKit
const userSig=genTestUserSig({
  SDKAppID,
  secretKey,
  userID,
}).userSig
TUIKit.login({
  userID: userID,
  userSig:userSig // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
});

// const app=createApp(App);
const app=createApp(App);
// app.use(router);
app.use(TUIKit);
app.mount('#app');

// createApp(App).use(TUIKit,).mount('#app');

export{SDKAppID,secretKey}